/**
 * @generated SignedSource<<5d5bace029e6359fa8320e48976d6f34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginInitiateInput = {
  clientMutationId?: string | null;
  login: LoginInitiate;
};
export type LoginInitiate = {
  email: string;
  password: string;
};
export type EmailLoginInitiateMutation$variables = {
  input: LoginInitiateInput;
};
export type EmailLoginInitiateMutation$data = {
  readonly loginInitiate: {
    readonly errors: ReadonlyArray<{
      readonly messages: ReadonlyArray<string>;
      readonly path: string;
    }>;
  };
};
export type EmailLoginInitiateMutation = {
  response: EmailLoginInitiateMutation$data;
  variables: EmailLoginInitiateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messages",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailLoginInitiateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginInitiatePayload",
        "kind": "LinkedField",
        "name": "loginInitiate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailLoginInitiateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LoginInitiatePayload",
        "kind": "LinkedField",
        "name": "loginInitiate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60f56b0796aa2f646b16a663e0b19048",
    "id": null,
    "metadata": {},
    "name": "EmailLoginInitiateMutation",
    "operationKind": "mutation",
    "text": "mutation EmailLoginInitiateMutation(\n  $input: LoginInitiateInput!\n) {\n  loginInitiate(input: $input) {\n    errors {\n      path\n      messages\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74f5f98f6b925355f3b7e0894ebcbc01";

export default node;
